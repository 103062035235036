import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {IonInfiniteScroll, ModalController} from '@ionic/angular';
import {HelperService} from "../../service/helper.service";
import {ApiService} from "../../service/api.service";
import {TranslateConfigService} from "../../service/translate-config.service";
import {SearchComponent} from "../../search/search.component";
import {DepositDetailComponent} from "../deposit-detail/deposit-detail.component";

@Component({
  selector: 'app-deposit-his-list',
  templateUrl: './deposit-his-list.component.html',
  styleUrls: ['./deposit-his-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DepositHisListComponent implements OnInit {
  @ViewChild(IonInfiniteScroll, {static: false}) infiniteScroll: IonInfiniteScroll;
  recordList = [];
  pageNumber: number;

  constructor(
    public modalCtr: ModalController,
    private hpService: HelperService,
    private apiService: ApiService
  ) {
    this.pageNumber = 0;
    this.recordList = [];
  }

  ngOnInit() {
    this.getData();
  }

  getData(event: any = null, searchParam: any = null): void {
    const loaderCustom = this.hpService.loaderCommon();
    this.apiService.getDepositHistoryList(this.pageNumber, loaderCustom).subscribe(res => {
      if (res.status === this.hpService.ERROR_CODE_SUCCESS) {
        if (res.data.length > 0) {
          this.recordList.push(...this.dataStatus(res.data));
        }
        //  this.infiniteScroll.disabled = false;
        if (event) {
          event.target.complete();
        }
      } else {
        this.hpService.handleError(res.message);
        //  this.infiniteScroll.disabled = true;
        if (event) {
          event.target.complete();
        }
      }
    }, err => {
      // this.infiniteScroll.disabled = true;
      if (event) {
        event.target.complete();
      }
    });
  }

  loadData(event: any = null): void {
    this.pageNumber++;
    this.getData(event);
  }

  doRefresh(event: any) {
    this.pageNumber = 0;
    this.recordList = [];
    this.getData(event);
  }

  dataStatus(list: any) {
    list.map(item => {
      item['statusName'] = this.hpService.convertStatusTrans(item['status']);
      return item;
    });
    return list;
  }

  async closeModal() {
    await this.modalCtr.dismiss();
  }

  async openSearchModal(opts = {}) {
    const modal = await this.modalCtr.create({
      component: SearchComponent,
      mode: 'ios',
      cssClass: 'modal-bottom'
    });

    modal.onDidDismiss()
      .then((ev) => {
        if (ev.data !== undefined) {
          const searchParam = ev.data;
          this.pageNumber = 0;
          this.getData(null, searchParam);
        }

      });

    return await modal.present();
  }

  openDetail(item: any) {
    if (item.status == this.hpService.STATUS_TRANS_PROCESSING) {
      this.presentModalDetail(item);
    }
  }

  async presentModalDetail(item: any) {
    const modal = await this.modalCtr.create({
      component: DepositDetailComponent,
      mode: 'md',
      cssClass: "modal-deposit-bottom",
      componentProps: {
        "item": item
      }
    });
    return await modal.present();
  }

}
