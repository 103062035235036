import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HelperService} from './helper.service';
import {RequestUtilService} from './request-util.service';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private hpService: HelperService,
    protected requestService: RequestUtilService
  ) {
  }

  verifyToken(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.URL_VERIFY_TOKEN, data, loadingCtrl);
  }

  login(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPBody(environment.endPointApi + this.hpService.URL_LOGIN, data, loadingCtrl);
  }

  register(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPBody(environment.endPointApi + this.hpService.URL_REGISTER, data, loadingCtrl);
  }

  getUserInfoData(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.URL_USER_INFO, data, loadingCtrl);
  }

  getUserBalance(): Observable<any> {
    return this.requestService.requestHTTPGETParamsTokenHeaderData(environment.endPointApi + this.hpService.URL_USER_BALANCE);
  }

  fundTransfer(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.URL_FUND_TRANSFER, data, loadingCtrl);
  }

  updateUserInfo(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.URL_UPDATE_USER_INFO, data, loadingCtrl);
  }

  changePassword(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.URL_CHANGE_PASSWORD2, data);
  }

  subGiftCode(giftCode: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPGETParamsTokenHeader(environment.endPointApi + this.hpService.URL_SUB_GIFT_CODE + giftCode, loadingCtrl);
  }

  updateLogin(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.URL_UPDATE_LOGIN + '?login=' + data.username + '&fbToken=' + data.accessToken, {}, loadingCtrl);
  }

  updatePassword2(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.URL_UPDATE_PASSWORD2, data, loadingCtrl);
  }

  changePassword2(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.URL_CHANGE_PASSWORD2, data, loadingCtrl);
  }

  getAppVersion(loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPGET(environment.endPointApi + this.hpService.URL_GET_APP_VERSION, loadingCtrl);
  }

  getQRCode(loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPGET(environment.endPointApi + this.hpService.URL_GET_QRCODE, loadingCtrl);
  }

  getListAgency(search: any, loadingCtrl?: any): Observable<any> {
    let url = environment.endPointApi + this.hpService.URL_GET_LIST_AGENCY;
    if (search != null && search != '') {
      url += '?loginname=' + search + '&phone=' + search;
    }
    return this.requestService.requestHTTPGETParamsTokenHeader(url, loadingCtrl);
  }

  getHisBetTX(pageNum: any) {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.MINI_HIS_BET_TX + '?page=' + pageNum + '&size=9&sort=id%2Cdesc', {});
  }

  getRankTX() {
    return this.requestService.requestHTTPGETParamsTokenHeader(environment.endPointApi + this.hpService.MINI_RANK_TX);
  }

  thongKePhienTX(txId: any) {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.MINI_THONG_KE_PHIEN_TX + txId, {});
  }

  getHisBetTD(login: any, pageNum: any) {
    return this.requestService.requestHTTPGETParamsTokenHeader(environment.endPointApi + this.hpService.MINI_HIS_BET_TD + '?loginname=' + login + '&page=' + pageNum + '&size=14&sort=id%2Cdesc');
  }

  getRankTD() {
    return this.requestService.requestHTTPGETParamsTokenHeader(environment.endPointApi + this.hpService.MINI_RANK_TD);
  }

  getHisBetXD(pageNum: any) {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.MINI_HIS_BET_XD + '?page=' + pageNum + '&size=9&sort=id%2Cdesc', {});
  }

  getRankXD() {
    return this.requestService.requestHTTPGETParamsTokenHeader(environment.endPointApi + this.hpService.MINI_RANK_XD);
  }

  thongKePhienXD(txId: any) {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.MINI_THONG_KE_PHIEN_XD + txId, {});
  }

  getAdminBankList(loadingCtrl?: any) {
    return this.requestService.requestHTTPGETParamsTokenHeader(environment.endPointApi + this.hpService.URL_GET_ALL_ADMIN_BANK, loadingCtrl);
  }

  createUserBank(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.URL_CREATE_USER_BANK, data, loadingCtrl);
  }

  updateUserBank(data: any, loadingCtrl?: any): Observable<any> {
    let url = environment.endPointApi + this.hpService.URL_UPDATE_USER_BANK + data.id;
    return this.requestService.requestHTTPParamsTokenHeader(url, data, loadingCtrl);
  }

  deleteUserBank(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.URL_DELETE_USER_BANK, data, loadingCtrl);
  }

  getUserBankList(loadingCtrl?: any) {
    return this.requestService.requestHTTPGETParamsTokenHeader(environment.endPointApi + this.hpService.URL_GET_ALL_USER_BANK, loadingCtrl);
  }

  createDeposit(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.URL_CREATE_DEPOSIT, data, loadingCtrl);
  }

  uploadDeposit(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPFormTokenHeader(environment.endPointApi + this.hpService.URL_UPLOAD_DEPOSIT, data, loadingCtrl);
  }

  getDepositHistoryList(pageNumber: any, loadingCtrl?: any) {
    const url = environment.endPointApi + this.hpService.URL_GET_HISTORY_DEPOSIT + '?page=' + pageNumber + '&size=10&sort=id,desc';
    return this.requestService.requestHTTPGETParamsTokenHeader(url, loadingCtrl);
  }

  createWithdraw(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.URL_CREATE_WITHDRAW, data, loadingCtrl);
  }

  getWithdrawHistoryList(pageNumber: any, loadingCtrl?: any) {
    const url = environment.endPointApi + this.hpService.URL_GET_HISTORY_WITHDRAW + '?page=' + pageNumber + '&size=10&sort=id,desc';
    return this.requestService.requestHTTPGETParamsTokenHeader(url, loadingCtrl);
  }

  getTransactionHistories(data: any, loadingCtrl?: any) {
    let url = environment.endPointApi + this.hpService.URL_GET_BALANCE_HISTORIES
      + '?action=' + data.action + '&page=' + data.pageNumber + '&size=10&sort=id,desc';
    if (data.startTime != null && data.endTime != null) {
      url += '&startTime=' + data.startTime + '&endTime=' + data.endTime;
    }
    return this.requestService.requestHTTPGETParamsTokenHeader(url, loadingCtrl);
  }

  getEvent(loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPGET(environment.endPointApi + this.hpService.URL_GET_EVENT, loadingCtrl);
  }

  getServerGame(loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPGETParamsTokenHeader(environment.endPointApi + this.hpService.URL_GET_SERVER_GAME, loadingCtrl);
  }

  getListBank(loadingCtrl?: any) {
    return this.requestService.requestHTTPGETParamsTokenHeader(environment.endPointApi + this.hpService.URL_GET_LIST_BANK, loadingCtrl);
  }

  getWallet3RD(gameId: any, loadingCtrl?: any): Observable<any> {
    // SG =1,LOTTERY=2,SBO=3,EVOLUTION=4,EVOLUTION_GS=5,LIVE22=6,EBET=7,WM=8,IBC=9
    const url = environment.endPointApi + this.hpService.WALLET_GAME + '?gameId=' + gameId;
    return this.requestService.requestHTTPGETParamsTokenHeader(url, loadingCtrl);
  }

  launchGame(gameId: any, subGameId?: any, loadingCtrl?: any): Observable<any> {
    let url = environment.endPointApi + this.hpService.LAUNCH_GAME;
    // IOS=1,ANDROID=2,WAP=3,WEB=4
    // SG =1,LOTTERY=2,SBO=3,EVOLUTION=4,EVOLUTION_GS=5,LIVE22=6,EBET=7,WM=8,IBC=9
    if (this.hpService.isDesktop) {
      url += '?os=4';
    } else if (this.hpService.isMobileWeb) {
      url += '?os=3';
    } else if (this.hpService.isMobileNative) {
      url += '?os=2';
    } else {
      url += '?os=1';
    }
    url += '&gameId=' + gameId;
    if (subGameId != null) {
      url += '&id=' + subGameId;
    }
    return this.requestService.requestHTTPGETParamsTokenHeader(url, loadingCtrl);
  }

  getGame3rd(pType: any, gameType?: any, gameCode?: any, loadingCtrl?: any): Observable<any> {
    let url = environment.endPointApi + this.hpService.URL_GAME_3RD;
    url += '?pType=' + pType;
    if (gameType != null && gameType !== '') {
      url += '&gameType=' + gameType;
    }
    if (gameCode != null && gameCode !== '') {
      url += '&gameId=' + gameCode;
    }
    return this.requestService.requestHTTPParamsTokenHeader(url, {}, loadingCtrl);
  }

  transferGame(data: any, loadingCtrl?: any): Observable<any> {
    // SG =1,LOTTERY=2,SBO=3,EVOLUTION=4,EVOLUTION_GS=5,LIVE22=6,EBET=7,WM=8,IBC=9
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.TRANSFER_GAME, data, loadingCtrl);
  }

  getCSKH(loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPGET(environment.endPointApi + this.hpService.URL_GET_CSKH, loadingCtrl);
  }

  getPromotion(id?: any, loadingCtrl?: any): Observable<any> {
    let url = environment.endPointApi + this.hpService.URL_GET_PROMO;
    if (id != null) {
      url = environment.endPointApi + this.hpService.URL_GET_PROMO_DETAIL + '/' + id;
    }
    return this.requestService.requestHTTPGET(url, loadingCtrl);
  }

  rewardSetCode(refCode: any, loadingCtrl?: any): Observable<any> {
    const url = environment.endPointApi + this.hpService.URL_REWARD_SET_CODE + '?code=' + refCode;
    return this.requestService.requestHTTPParamsTokenHeader(url, {}, loadingCtrl);
  }

  rewardListChill(data: any, loadingCtrl?: any): Observable<any> {
    const url = environment.endPointApi + this.hpService.URL_REWARD_LIST_CHILL
      + '?page=' + data.pageNumber + '&size=20&sort=level,asc';
    return this.requestService.requestHTTPGETParamsTokenHeader(url, loadingCtrl);
  }

  rewardListCommission(data: any, loadingCtrl?: any): Observable<any> {
    let url = environment.endPointApi + this.hpService.URL_REWARD_LIST_COMMISION
      + '?page=' + data.pageNumber + '&size=20&sort=id,desc';
    if (data.reportDate != null && data.reportDate !== '') {
      url += '&reportDate=' + data.reportDate;
    }

    if (data.status != null && data.status !== '') {
      url += '&status=' + data.status;
    }

    return this.requestService.requestHTTPGETParamsTokenHeader(url, loadingCtrl);
  }

  rewardReceive(reportDate: any, loadingCtrl?: any): Observable<any> {
    let url = environment.endPointApi + this.hpService.URL_REWARD_RECEIVE;
    if (reportDate != null && reportDate !== '') {
      url += '?reportDate=' + reportDate;
    }
    return this.requestService.requestHTTPParamsTokenHeader(url, {}, loadingCtrl);
  }

  reportAgent(data: any, loadingCtrl?: any): Observable<any> {
    let url = environment.endPointApi + this.hpService.URL_REPORT_AGENT
      + '?page=' + data.pageNumber + '&size=10';
    if (data.startTime != null && data.endTime != null) {
      url += '&startTime=' + data.startTime + '&endTime=' + data.endTime;
    }
    if (data.loginName != null) {
      url += '&loginName=' + data.loginName;
    }
    return this.requestService.requestHTTPGETParamsTokenHeader(url, loadingCtrl);
  }

  reportBonus(data: any, loadingCtrl?: any): Observable<any> {
    let url = environment.endPointApi + this.hpService.URL_REPORT_BONUS
      + '?page=' + data.pageNumber + '&size=10';
    if (data.date != null) {
      url += '&date=' + data.date;
    }
    return this.requestService.requestHTTPGETParamsTokenHeader(url, loadingCtrl);
  }

  getListGameJILI() {
    return this.requestService.requestHTTPGET(environment.domainDownload + this.hpService.URL_GET_LIST_JILI);
  }

  sendSMS(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPBody(environment.endPointApi + this.hpService.URL_SEND_SMS, data, loadingCtrl);
  }

  verifySMS(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPBody(environment.endPointApi + this.hpService.URL_VERIFY_SMS, data, loadingCtrl);
  }

  getMessageUnRead(loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPGETParamsTokenHeader(environment.endPointApi + this.hpService.URL_MESSAGE_UNREAD, loadingCtrl);
  }

  getMessageTop(loadingCtrl?: any) {
    return this.requestService.requestHTTPGETParamsTokenHeader(environment.endPointApi + this.hpService.URL_MESSAGE_TOP, loadingCtrl);
  }

  readMessage(id: any, loadingCtrl?: any): Observable<any> {
    const url = environment.endPointApi + this.hpService.URL_MESSAGE_READ + id;
    return this.requestService.requestHTTPGETParamsTokenHeader(url, loadingCtrl);
  }

  registerFCM(data: any, loadingCtrl?: any): Observable<any> {
    return this.requestService.requestHTTPParamsTokenHeader(environment.endPointApi + this.hpService.URL_REGISTER_FCM, data, loadingCtrl);
  }
}
