import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {ApiService} from '../service/api.service';
import {Router} from '@angular/router';
import {HelperService} from '../service/helper.service';
import {TranslateConfigService} from '../service/translate-config.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {
  password = '';
  oldPassword = '';
  isChange = false;
  showPass: boolean;
  constructor(
    private hpService: HelperService,
    public modalCtr: ModalController,
    public apiService: ApiService,
    private route: Router,
    private translateConfigService: TranslateConfigService,
    private navParams: NavParams
  ) {
    if (this.navParams.get('isChange')) {
      this.isChange = this.navParams.get('isChange');
    }
    this.showPass = false;
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  async closeModal() {
    await this.modalCtr.dismiss(false);
  }

  updatePassword(ev: any) {
    if (this.password === undefined || this.password.trim() === '') {
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('UPDATE-PASSWORD.valid-password-blank'));
      return;
    }

    const loaderCustom = this.hpService.loaderCommon();

    const params = {
      newPassword: this.password.trim(),
    };

    this.apiService.updatePassword2(params, loaderCustom).subscribe(
      data => {
        if (data.status === this.hpService.ERROR_CODE_SUCCESS) {
          this.modalCtr.dismiss(true);
        } else {
          this.hpService.handleError(data.message);
        }
      }
    );
  }

  changePassword(ev: any) {
    if (this.oldPassword === undefined || this.oldPassword.trim() === '') {
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('UPDATE-PASSWORD.valid-old-password-blank'));
      return;
    }

    if (this.password === undefined || this.password.trim() === '') {
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('UPDATE-PASSWORD.valid-password-blank'));
      return;
    }

    const loaderCustom = this.hpService.loaderCommon();

    const params = {
      newPassword: this.password.trim(),
      currentPassword: this.oldPassword.trim()
    };

    this.apiService.changePassword2(params, loaderCustom).subscribe(
      data => {
        if (data.status === this.hpService.ERROR_CODE_SUCCESS) {
          this.modalCtr.dismiss(true);
        } else {
          this.hpService.handleError(data.message);
        }
      }
    );
  }
}
