import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {HelperService} from '../service/helper.service';
import {ApiService} from '../service/api.service';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotifyComponent implements OnInit, OnDestroy {

  listMessage = [];

  constructor(
    public modalCtr: ModalController,
    private hpService: HelperService,
    private apiService: ApiService
  ) {

  }

  ngOnInit() {
    this.getListMessage();
  }

  ngOnDestroy(): void {
  }

  getListMessage() {
    this.apiService.getMessageTop().subscribe(data => {
      if (data.status === this.hpService.ERROR_CODE_SUCCESS) {
        this.listMessage = data.data;
      } else {
        this.hpService.handleError(data.message);
      }
    });
  }

  readMessage(item: any) {
    if (item.status === 0) {
      this.apiService.readMessage(item.id).subscribe(data => {
        if (data.status === this.hpService.ERROR_CODE_SUCCESS) {
          this.listMessage.find(it => it.id === item.id).status = 1;
          this.hpService.observableNotify.next();
        } else {
          this.hpService.handleError(data.message);
        }
      });
    }
  }

  async dismissModal() {
    await this.modalCtr.dismiss();
  }

}
