import {Injectable} from '@angular/core';
import {AlertController, LoadingController, ToastController} from '@ionic/angular';
import {Observable, Subject} from 'rxjs';
import {TranslateConfigService} from './translate-config.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  isDesktop = false;
  isMobileNative = false;
  isMobileWeb = false;
  isIOSDevice = false;
  isAndroidDevice = false;
  serialDevice = null;

  URL_VERIFY_TOKEN = '/verifytoken';
  URL_LOGIN = '/login';
  URL_REGISTER = '/player/registerAcc';
  URL_USER_INFO = '/player/users/info';
  URL_USER_BALANCE = '/player/user/balance';
  URL_GAME_3RD = '/gs/launch';
  URL_FUND_TRANSFER = '/gs/transfer';
  URL_UPDATE_USER_INFO = '/updateUserV1';
  URL_CHANGE_PASSWORD = '/account/change-password';
  URL_SUB_GIFT_CODE = '/receive-gift-code?giftCode=';
  URL_UPDATE_LOGIN = '/update-login-name';
  URL_UPDATE_PASSWORD2 = '/player/update-password2';
  URL_CHANGE_PASSWORD2 = '/player/change-password2';
  URL_GET_APP_VERSION = '/last-app-versions';
  URL_GET_LIST_AGENCY = '/agent/list';
  URL_GET_ALL_ADMIN_BANK = '/v1/admin-bank/findAll';
  URL_GET_ALL_USER_BANK = '/v1/user-bank/findAll';
  URL_CREATE_USER_BANK = '/v1/user-bank/create';
  URL_UPDATE_USER_BANK = '/v1/user-bank/update/';
  URL_DELETE_USER_BANK = '/v1/user-bank/delete/';
  URL_GET_HISTORY_DEPOSIT = '/v1/deposit/findAll';
  URL_CREATE_DEPOSIT = '/v1/deposit/create';
  URL_UPLOAD_DEPOSIT = '/v1/deposit/upload';
  URL_GET_HISTORY_WITHDRAW = '/v1/withdraw/findAll';
  URL_CREATE_WITHDRAW = '/v1/withdraw/create';
  URL_GET_BALANCE_HISTORIES = '/transaction/histories';
  URL_GET_EVENT = '/v1/events';
  URL_GET_LIST_BANK = '/v1/banks/findAll';
  URL_GET_SERVER_GAME = '/api/player/v1/link';

  URL_GET_CSKH = '/config/cskh';
  URL_GET_PROMO = '/promotion/findAll';
  URL_GET_PROMO_DETAIL = '/promotion/findOne';
  URL_GET_QRCODE = '/qrCode';

  URL_REWARD_SET_CODE = '/reward/set-code';
  URL_REWARD_LIST_CHILL = '/reward/findByParent';
  URL_REWARD_LIST_COMMISION = '/reward/findListReward';
  URL_REWARD_RECEIVE = '/reward/receive';

  URL_REPORT_AGENT = '/report-agent-lvn';
  URL_REPORT_BONUS = '/report-bonus';

  LAUNCH_GAME = '/gs/launch';
  TRANSFER_GAME = '/game3rd/transfer';
  WALLET_GAME = '/game3rd/wallet';
  URL_GET_LIST_JILI = '/json/ae8386-jili.json';

  URL_SEND_SMS = '/user-phones';
  URL_VERIFY_SMS = '/verify';

  URL_MESSAGE_UNREAD = "/messages/count-unread";
  URL_MESSAGE_TOP = "/messages/find-top15-by-login";
  URL_MESSAGE_READ = "/messages/readOne?id=";

  URL_REGISTER_FCM = "/device-fcms";

  MINI_TOPIC_TX = '/topic/tx';
  MINI_TOPIC_QUEUE_TX = '/user/queue/tx';
  MINI_MSG_BET_TX = '/topic/bet';
  MINI_MSG_PUBLIC_TX = '/topic/public'; //lấy lịch sử chat
  MINI_MSG_CHAT_TX = '/topic/chats';
  MINI_MSG_LSP_TX = '/topic/lichsuphien'; //lấy lịch sử phiên
  MINI_HIS_BET_TX = '/tx/lichsucuoc';
  MINI_RANK_TX = '/tx-ranks?type=1';
  MINI_THONG_KE_PHIEN_TX = '/tx/thongkephien/';

  MINI_TOPIC_TD = '/topic/rocket';
  MINI_TOPIC_QUEUE_TD = '/user/queue/rocket';
  MINI_MSG_BET_TD = '/betRocket';
  MINI_MSG_PUBLIC_TD = '/topic/public-rocket'; //lấy lịch sử chat
  MINI_MSG_CHAT_TD = '/topic/rocketchats';
  MINI_MSG_LSP_TD = '/rocket/lsphien';
  MINI_MSG_RATE_TD = '/topic/rocket-rates';
  MINI_HIS_BET_TD = '/rocket-records-history';
  MINI_RANK_TD = '/tx-ranks?type=2';

  MINI_TOPIC_XD = '/topic/xd';
  MINI_TOPIC_QUEUE_XD = '/user/queue/xd';
  MINI_TOPIC_CHAT_XD = '/topic/xocdia';
  MINI_MSG_BET_XD = '/topic/betxd';
  MINI_MSG_PUBLIC_XD = '/topic/publicxd'; //lấy lịch sử chat
  MINI_MSG_CHAT_XD = '/topic/chatxd';
  MINI_MSG_LSP_XD = '/topic/lichsuphienxd';
  MINI_HIS_BET_XD = '/xd/lichsucuoc';
  MINI_RANK_XD = '/tx-ranks?type=3';
  MINI_THONG_KE_PHIEN_XD = '/xd/thongkephien/';

  BALANCE_QUEUE = '/user/queue/balance';

  PTYPE_LIVE_CASINO = 'GE';
  PTYPE_SLOT = 'L4';
  MAIN_WALLET = -1;
  GAME_ID_SG = 1;
  GAME_ID_LOTTERY = 2;
  GAME_ID_SBO = 3;
  GAME_ID_EVOLUTION = 4;
  GAME_ID_LIVE22 = 5;
  GAME_ID_EVOLUION_GS = 6;
  GAME_ID_WM = 8;
  GAME_ID_EBET = 7;
  GAME_ID_IBC = 9;
  GAME_ID_PRAC = 10;
  GAME_ID_ESPORT = 11;
  GAME_ID_SLOT = 12;
  GAME_ID_V8 = 13;
  GAME_ID_JILI = 14;
  GAME_ID_CQ9= 15;
  GAME_ID_SA= 16;
  GAME_ID_PLAYSTAR= 17;
  GAME_ID_UG = 18;
  GAME_ID_IG = 19;
  GAME_ID_YGR = 20;
  GAME_ID_BNG = 21;

  ERROR_CODE_SUCCESS = 0;
  ERROR_CODE_MAINTAIN = -1;
  ERROR_UPDATE_LOGIN = 9;
  ERROR_UPDATE_PHONE = -5;

  ACTION_DEPOSIT = 1;
  ACTION_WITHDRAW = 2;
  ACTION_FUND_DEPOSIT = 12;
  ACTION_FUND_WITHDRAW = 13;
  ACTION_BET_TX = 3;
  ACTION_WIN_TX = 4;
  ACTION_REFUND_TX = 5;
  ACTION_BET_XD = 6;
  ACTION_WIN_XD = 7;
  ACTION_REFUND_XD = 8;
  ACTION_JACKPOT_XD = 9;
  ACTION_BET_TD = 10;
  ACTION_WIN_TD = 11;
  ACTION_MINIGAME_STR = '3,4,5,6,7,8,9,10,11';
  ACTION_DEPOSIT_WITHDRAW_STR = '1,2,19';
  ACTION_FUND_STR = '12,13,14,15,16,17,18,20,21,22,23,24,25,26,27,28,29,30,31,33,35,36,37';

  ACTION_DEPOSIT_SG = 20;
  ACTION_WITHDRAW_SG = 21;
  ACTION_DEPOSIT_SBO = 22;
  ACTION_WITHDRAW_SBO = 23;
  ACTION_DEPOSIT_WM = 24;
  ACTION_WITHDRAW_WM = 25;
  ACTION_DEPOSIT_EBET = 26;
  ACTION_WITHDRAW_EBET = 27;
  ACTION_DEPOSIT_LOTO = 28;
  ACTION_WITHDRAW_LOTO = 29;
  ACTION_DEPOSIT_IBC = 30;
  ACTION_WITHDRAW_IBC = 31;

  REWARD_FRIEND = 33;
  REFUND_GAME = 35;
  DEPOSIT_V8 = 36;
  WITHDRAW_V8 = 37;

  // REWARD_FRIEND(33, "REWARD ", "Giới thiệu bạn bè", "+"),
  // REFUND_GAME(35, "REFUND_GAME ", "Hoàn trả games", "+"),
  // DEPOSIT_V8(36, "DEPOSIT_V8", "DEPOSIT_V8", "-"),
  // WITHDRAW_V8(37, "WITHDRAW_V8", "WITHDRAW_V8", "+"),

  STATUS_TRANS_PROCESSING = 0;
  STATUS_TRANS_SUCCESS = 1;
  STATUS_TRANS_FAIL = 2;

  LOTTERY_SERVICE = 'LotteryService.aspx';
  USER_SERVICE = 'LotteryService.aspx';

  listNameFaceApplySTMNMT = [
    'MMC', 'Jz45', 'CSSSC', 'PHP', 'SSCBF', 'CqSSC',
    'xsthuathienhue', 'xsphuyen', 'xsdaclac', 'xsquangnam', 'xsdanang', 'xskhanhhoa', 'xsbinhdinh', 'xsquangtri', 'xsquangbinh', 'xsgialai', 'xsninhthuan', 'xsquangngai', 'xsdacnong', 'xskontum',
    'xshochiminh', 'xshochiminhvip', 'xsdongthap', 'xscamau', 'xsbentre', 'xsvungtau', 'xsbaclieu', 'xsdongnai', 'xscantho', 'xssoctrang', 'xstayninh', 'xsangiang', 'xsbinhthuan', 'xsvinhlong', 'xsbinhduong', 'xstravinh', 'xslongan', 'xsbinhphuoc', 'xshaugiang', 'xstiengiang', 'xskiengiang', 'xsdalat', 'xsdanangvip'
  ];
  listNameFaceApplyMB = ['MB', 'HNVIP', 'VNVIP'];
  listNameFaceApplyMBDB = ['MBDB'];
  listNameXSST = ['MMC', 'Jz45', 'CSSSC', 'PHP', 'SSCBF', 'CqSSC'];
  alertResultBet: any;

  mapKeyDay = [
    {key: 'all', text: 'Tất Cả'},
    {key: 't2', text: 'Thứ Hai', day: 1},
    {key: 't3', text: 'Thứ Ba', day: 2},
    {key: 't4', text: 'Thứ Tư', day: 3},
    {key: 't5', text: 'Thứ Năm', day: 4},
    {key: 't6', text: 'Thứ Sáu', day: 5},
    {key: 't7', text: 'Thứ Bảy', day: 6},
    {key: 'cn', text: 'Chủ Nhật', day: 0}
  ];

  observableNotify = new Subject<any>();

  observableLoading = new Subject<any>();
  observableBalance = new Subject<any>();
  observableResetAuth = new Subject<any>();


  observableAlertMiniGame = new Subject<any>();

  observableGameTXCB = new Subject<any>();
  observableReloadHisBet = new Subject<any>();
  observableHisPhienTXCB = new Subject<any>();
  observableIsValidNextTKPTXCB = new Subject<any>();


  observableGameTrenDuoi = new Subject<any>();
  observableReloadHisBetTrenDuoi = new Subject<any>();

  observableGameXD = new Subject<any>();
  observableReloadHisBetXD = new Subject<any>();
  observableHisPhienXD = new Subject<any>();


  constructor(
    private alertCtrl: AlertController,
    protected loadingCtrl: LoadingController,
    private translateConfigService: TranslateConfigService,
    public toastController: ToastController
  ) {

  }

  convertStatusTrans(status: any) {
    const state: any = {
      0: this.translateConfigService.getTranslateService().instant('DEPOSIT.status-processing'),
      1: this.translateConfigService.getTranslateService().instant('DEPOSIT.status-success'),
      2: this.translateConfigService.getTranslateService().instant('DEPOSIT.status-reject'),
      3: this.translateConfigService.getTranslateService().instant('DEPOSIT.status-fail'),
      4: this.translateConfigService.getTranslateService().instant('DEPOSIT.status-expired'),
      5: this.translateConfigService.getTranslateService().instant('DEPOSIT.status-processing')
    };
    return state[status];
  }

  static numberWithCommas(x: number): string {
    const num = Number(x).toFixed(0);
    return num.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  async presentToastGame(msg: any) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      cssClass: 'toast-game-custom',
    });
    toast.present();
  }

  async presentToast(msg: any) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      cssClass: 'toast-custom',
    });
    toast.present();
  }

  async loaderCommon() {
    const loading = await this.loadingCtrl.create({
      message: this.translateConfigService.getTranslateService().instant('MESSAGE.wait'),
      mode: 'ios'
    });
    await loading.present();
    return loading;
  }

  async handleError(msg: string, cb?: any, titleBtn = this.translateConfigService.getTranslateService().instant('MESSAGE.ok')) {
    if (msg === '-1') {
      return;
    }

    const alert = await this.alertCtrl.create({
      header: this.translateConfigService.getTranslateService().instant('MESSAGE.notify'),
      message: msg,
      backdropDismiss: false,
      buttons: [
        {
          text: titleBtn,
          cssClass: 'warning',
          handler: () => {
            if (cb && typeof cb === 'function') {
              cb();
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async handlerSuccess(msg: string, cb?: any, title?: any) {
    const alert = await this.alertCtrl.create({
      header: title ? title : this.translateConfigService.getTranslateService().instant('MESSAGE.success'),
      message: msg,
      backdropDismiss: false,
      cssClass: 'alertSuccess',
      buttons: [
        {
          text: this.translateConfigService.getTranslateService().instant('MESSAGE.ok'),
          cssClass: 'warning',
          handler: () => {
            if (cb && typeof cb === 'function') {
              cb();
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async confirmAlert(msg: string, cb: any) {
    const alert = await this.alertCtrl.create({
      header: this.translateConfigService.getTranslateService().instant('MESSAGE.confirm'),
      message: msg,
      buttons: [
        {
          text: this.translateConfigService.getTranslateService().instant('MESSAGE.cancel'),
          role: 'cancel',
        }, {
          text: this.translateConfigService.getTranslateService().instant('MESSAGE.ok'),
          handler: () => {
            if (cb && typeof cb === 'function') {
              cb();
            }
          }
        }
      ]
    });

    await alert.present();
  }

  // async confirmWithdraw(cb: any) {
  //   const alert = await this.alertCtrl.create({
  //     header: this.translateConfigService.getTranslateService().instant('MESSAGE.confirm'),
  //     inputs: [
  //       {
  //         name: 'password',
  //         placeholder: this.translateConfigService.getTranslateService().instant('UPDATE-PASSWORD.password'),
  //         type: 'password'
  //       }
  //     ],
  //     buttons: [
  //       {
  //         text: this.translateConfigService.getTranslateService().instant('MESSAGE.cancel'),
  //         role: 'cancel',
  //       }, {
  //         text: this.translateConfigService.getTranslateService().instant('MESSAGE.ok'),
  //         handler: data => {
  //           if (cb && typeof cb === 'function') {
  //             cb(data);
  //           }
  //         }
  //       }
  //     ]
  //   });

  //   await alert.present();
  // }

  async confirmUpdateAppVersion(cb: any) {
    const alert = await this.alertCtrl.create({
      header: this.translateConfigService.getTranslateService().instant('UPDATE-VERSION.title'),
      message: this.translateConfigService.getTranslateService().instant('UPDATE-VERSION.msg'),
      buttons: [
        {
          text: this.translateConfigService.getTranslateService().instant('UPDATE-VERSION.cancel'),
          role: 'cancel',
        }, {
          text: this.translateConfigService.getTranslateService().instant('UPDATE-VERSION.update'),
          handler: () => {
            if (cb && typeof cb === 'function') {
              cb();
            }
          }
        }
      ]
    });

    await alert.present();
  }

  copyToClipboard(text: string, elWrap?): { status: boolean; msg: string } {
    let documentBodyTemp = document.body;
    if (elWrap) {
      documentBodyTemp = elWrap;
    }

    const textarea = document.createElement('textarea');
    const res: { status: boolean; msg: string } = {status: false, msg: ''};
    textarea.textContent = text;
    textarea.contentEditable = 'true';
    textarea.readOnly = false;
    textarea.style.background = 'transparent';
    textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge.
    documentBodyTemp.appendChild(textarea);
    textarea.setSelectionRange(0, text.length);
    document.execCommand('copy');
    textarea.select();
    try {
      document.execCommand('copy');  // Security exception may be thrown by some browsers.
    } catch (ex) {
      res.status = false;
      res.msg = this.translateConfigService.getTranslateService().instant('MESSAGE.copy-fail');
      this.presentToast(res.msg);
      return res;
    } finally {
      res.status = true;
      res.msg = this.translateConfigService.getTranslateService().instant('MESSAGE.copy-success');
      documentBodyTemp.removeChild(textarea);
      this.presentToast(res.msg);
    }
    return res;
  }

  getDateTimeHH_MM_SS(date) {
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    return `${hour}:${minute}:${second}`;
  }

  isPhoneNumberVietNam(phone) {
    const regEx = /((09|03|07|08|05|\+849|\+843|\+847|\+848|\+845)+([0-9]{8})\b)/g;
    return phone.search(regEx) === 0;
  }

  isPhoneNumberPhi(phone) {
    const regEx = /^(09|\+639)\d{9}$/;
    return phone.search(regEx) === 0;
  }

  getSizeObject(obj) {
    let size = 0;
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        size++;
      }
    }
    return size;
  }

  getDateTimeByFormat(date, format) {
    let day = date.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    if (format === 'DD-MM-YYYY') {
      return `${day}-${month}-${date.getFullYear()}`;
    } else if (format === 'MM-DD-YYYY') {
      return `${month}-${day}-${date.getFullYear()}`;
    } else if (format === 'YYYY-MM-DD') {
      return `${date.getFullYear()}-${month}-${day}`;
    } else if (format === 'DD/MM/YYYY') {
      return `${day}/${month}/${date.getFullYear()}`;
    } else if (format === 'MM/DD/YYYY') {
      return `${month}/${day}/${date.getFullYear()}`;
    } else if (format === 'YYYY/MM/DD') {
      return `${date.getFullYear()}/${month}/${day}`;
    }
    return `${day}/${month}/${date.getFullYear()}`;
  }

  convertMoneyTxt(money: number) {
    let moneyConvertStr = '';
    if (money < 1000) {
      moneyConvertStr = `${money}`;
    } else if (money >= 1000 && money < 1000000) {
      moneyConvertStr = `${money / 1000}K`;
    } else if (money >= 1000000) {
      moneyConvertStr = `${money / 1000000}M`;
    }
    return moneyConvertStr;
  }

  checkName(name: string) {
    const regex = /^([a-zA-Z0-9])+$/;
    return regex.test(name);
  }

  getObserverNotify(): Observable<any> {
    return this.observableNotify.asObservable();
  }

  getObserverLoading(): Observable<any> {
    return this.observableLoading.asObservable();
  }

  getObserverBalance(): Observable<any> {
    return this.observableBalance.asObservable();
  }

  getObserverResetAuth(): Observable<any> {
    return this.observableResetAuth.asObservable();
  }

  getObserverGameTXCB(): Observable<any> {
    return this.observableGameTXCB.asObservable();
  }

  getObserverHisPhienTXCB(): Observable<any> {
    return this.observableHisPhienTXCB.asObservable();
  }

  getObserverIsValidNextTKPTXCB(): Observable<any> {
    return this.observableIsValidNextTKPTXCB.asObservable();
  }

  getObserverAlertMiniGame(): Observable<any> {
    return this.observableAlertMiniGame.asObservable();
  }

  getObserverGameTrenDuoi(): Observable<any> {
    return this.observableGameTrenDuoi.asObservable();
  }

  getObserverHisBetTrenDuoi(): Observable<any> {
    return this.observableReloadHisBetTrenDuoi.asObservable();
  }

  getObserverHisBet(): Observable<any> {
    return this.observableReloadHisBet.asObservable();
  }

  getObserverGameXD(): Observable<any> {
    return this.observableGameXD.asObservable();
  }

  getObserverHisBetXD(): Observable<any> {
    return this.observableReloadHisBetXD.asObservable();
  }

  getObserverHisPhienXD(): Observable<any> {
    return this.observableHisPhienXD.asObservable();
  }

  randomText(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getMinMaxRandom(num: any) {
    const getLen = num.toString().trim().length;
    return ('' + Math.random()).substring(2, getLen + 2);
  }

  changeAlias(alias: string, removeNumber?: boolean) {
    let str = alias;
    str = str.toUpperCase();
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ắ|Ằ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, '');
    str = str.replace(/ + /g, '');
    if (removeNumber) {
      str = str.replace(/[0-9]/g, '');
    } else {
      str = str.replace(/ /g, '');
    }
    return str;
  }

  numbersOnlyValidator(value: any) {
    const pattern = /^[0-9\-]*$/;
    if (!pattern.test(value)) {
      return false;
    }
    return true;
  }
}
