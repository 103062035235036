import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomNumberPipe} from '../custom-number.pipe';
import {TranslateModule} from "@ngx-translate/core";
import {IonClickDirective} from "../../directive/ion-click.directive";
import {SafePipe} from "../safe-pipe";

@NgModule({
  declarations: [
    CustomNumberPipe,
    IonClickDirective,
    SafePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CustomNumberPipe,
    TranslateModule,
    IonClickDirective,
    SafePipe
  ]
})
export class SharedPipeModule {
}
