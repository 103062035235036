import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor() {
  }

  public get authentication(): any {
  /*  var date = new Date();
    let currentTime = date.getTime();
    var tokenExpired = localStorage.getItem('tokenExpired') ? Number(localStorage.getItem('tokenExpired')) : currentTime;
    if (currentTime - tokenExpired > 86400 * 7 * 1000) {
      this.resetAuth();
      return false;
    }*/
    return localStorage.getItem('auth');
  }

  public get tokenId(): any {
/*    var date = new Date();
    let currentTime = date.getTime();
    var tokenExpired = localStorage.getItem('tokenExpired') ? Number(localStorage.getItem('tokenExpired')) : currentTime;
    if (currentTime - tokenExpired > 86400 * 7 * 1000) {
      this.resetAuth();
      return false;
    }*/
    return localStorage.getItem('idToken');
  }

  public get isLoginFacebook(): any {
    var accessTokenFB = localStorage.getItem('accessTokenFB');
    if (accessTokenFB != null && accessTokenFB !== undefined && accessTokenFB !== '') {
      return true;
    }
    return false;
  }

  public get isUpdateLogin(): any {
    var accessTokenFB = localStorage.getItem('accessTokenFB');
    if (accessTokenFB != null && accessTokenFB !== undefined && accessTokenFB !== '') {
      var isUpdateLogin = localStorage.getItem('isUpdateLogin');
      if (isUpdateLogin != null && isUpdateLogin !== undefined && isUpdateLogin == 'false')
        return true;
    }
    return false;
  }

  resetAuth() {
    localStorage.removeItem('auth');
    localStorage.removeItem('idToken');
    localStorage.removeItem('tokenExpired');
    localStorage.removeItem('accessTokenFB');
    localStorage.removeItem('isUpdateLogin');
  }

}
