import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError, map, timeout} from 'rxjs/operators';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {AccountService} from "./account.service";
import {HelperService} from "./helper.service";

@Injectable({
  providedIn: 'root'
})
export class RequestUtilService {

  timeOut = 20000;

  constructor(
    protected http: HttpClient,
    private accountService: AccountService,
    private hpService: HelperService
  ) {
  }

  requestHTTPBody(url, data: any, loadingCtrl?: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
      })
    };
    return this.http.post<any>(url, data, httpOptions)
      .pipe(
        timeout(this.timeOut),
        map(res => {
          if (loadingCtrl != null)
            loadingCtrl.then(ldRes => ldRes.dismiss());
          return res;
        }),
        catchError((err) => {
          if (loadingCtrl != null)
            loadingCtrl.then(ldRes => ldRes.dismiss());
          return this.handleError(err);
        })
      );
  }

  requestHTTPParamsTokenHeader(url, data: any, loadingCtrl?: any, token?: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': `Bearer ${token != null ? token : this.accountService.tokenId}`
      })
    };
    return this.http.post<any>(url, data, httpOptions)
      .pipe(
        timeout(this.timeOut),
        map(res => {
          if (loadingCtrl != null)
            loadingCtrl.then(ldRes => ldRes.dismiss());
          return res;
        }),
        catchError((err) => {
          if (loadingCtrl != null)
            loadingCtrl.then(ldRes => ldRes.dismiss());
          return this.handleError(err);
        })
      );
  }

  requestHTTPFormTokenHeader(url, payload: any, loadingCtrl?: any, token?: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA',
        'Authorization': `Bearer ${token != null ? token : this.accountService.tokenId}`
      })
    };
    return this.http.post<any>(url, payload, httpOptions)
      .pipe(
        timeout(this.timeOut),
        map(res => {
          if (loadingCtrl != null)
            loadingCtrl.then(ldRes => ldRes.dismiss());
          return res;
        }),
        catchError((err) => {
          if (loadingCtrl != null)
            loadingCtrl.then(ldRes => ldRes.dismiss());
          return this.handleError(err);
        })
      );
  }

  requestHTTPGET(url, loadingCtrl?: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({})
    };
    return this.http.get<any>(url, httpOptions)
      .pipe(
        timeout(this.timeOut),
        map(res => {
          if (loadingCtrl != null)
            loadingCtrl.then(ldRes => ldRes.dismiss());
          return res;
        }),
        catchError((err) => {
          if (loadingCtrl != null)
            loadingCtrl.then(ldRes => ldRes.dismiss());
          return this.handleError(err);
        })
      );
  }

  requestHTTPGETParamsTokenHeader(url, loadingCtrl?: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': `Bearer ${this.accountService.tokenId}`
      })
    };
    return this.http.get<any>(url, httpOptions)
      .pipe(
        timeout(this.timeOut),
        map(res => {
          if (loadingCtrl != null)
            loadingCtrl.then(ldRes => ldRes.dismiss());
          return res;
        }),
        catchError((err) => {
          if (loadingCtrl != null)
            loadingCtrl.then(ldRes => ldRes.dismiss());
          return this.handleError(err);
        })
      );
  }

  requestHTTPGETParamsTokenHeaderData(url): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': `Bearer ${this.accountService.tokenId}`
      }),
      observe: 'response' as 'response'
    };
    return this.http.get<any>(url, httpOptions)
      .pipe(
        timeout(this.timeOut),
        map(res => {
          return res;
        }),
        catchError((err) => {
          return this.handleError(err);
        })
      );
  }

  requestHTTP(url, data: any, loadingCtrl?: any): Observable<any> {
    const body = this.createRequestOption(data);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
      }),
      withCredentials: true
    };

    return this.http.post<any>(url, body, httpOptions)
      .pipe(
        timeout(this.timeOut),
        map(res => {
          if (loadingCtrl != null)
            loadingCtrl.then(ldRes => ldRes.dismiss());
          return res;
        }),
        catchError((err) => {
          if (loadingCtrl != null)
            loadingCtrl.then(ldRes => ldRes.dismiss());
          return this.handleError(err);
        })
      );
  }

  handleError(error: HttpErrorResponse): Observable<never> | Observable<any> {
    let errMsg = '';
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
      this.hpService.handleError(error.error.message);
    } else {
      console.log(`Backend returned code ${error.status}, body was: ${error.error}`);
      switch (error.status) {
        case 500:
          errMsg = 'Máy chủ bảo trì. Vui lòng liên hệ CSKH .';
          break;
        case 504:
          errMsg = 'Không thể kết nối với máy chủ, vui lòng thử lại sau vài phút!';
          break;
        case 401:
          errMsg = 'Phiên làm việc của bạn đã hết hạn. Xin vui lòng đăng nhập lại!';
          this.accountService.resetAuth();
          break;
        default:
          errMsg = 'Lỗi hệ thống, vui lòng liên hệ CSKH !';
          return throwError(errMsg);
      }
      this.hpService.handleError(errMsg);
    }
    return throwError(errMsg);
  }

  requestMultiHTTP(url, data: any): Observable<any[]> {
    const body = [];
    const response = [];
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      withCredentials: true
    };
    if (window['serveApi']) {
      url = window['serveApi'] + url;
    }
    data.forEach(item => {
      body.push(this.createRequestOption(item));
    });
    body.forEach(item => {
      const request =
        this.http.post<any>(url, item, httpOptions)
          .pipe(
            timeout(15000),
            map(res => {
              return res;
            }),
            catchError((err) => this.handleError(err))
          );
      response.push(request);
    });
    return forkJoin(response);
  }

  protected toQueryPair(key, value) {
    if (typeof value === 'undefined') {
      return key;
    }
    return key + '=' + encodeURIComponent(value === null ? '' : String(value));
  }

  protected createRequestOption(obj) {
    let ret = [];
    // tslint:disable-next-line:forin
    for (const key in obj) {
      const values = obj[key];
      if (values && values.constructor === Array) {
        const queryValues = [];
        for (let i = 0, len = values.length, value; i < len; i++) {
          value = values[i];
          queryValues.push(this.toQueryPair(key + '[]', value));
        }
        ret = ret.concat(queryValues);
      } else if (values && values.constructor === Object) {
        // tslint:disable-next-line:forin
        for (const keySub in values) {
          ret.push(this.toQueryPair(key + '[' + keySub + ']', values[keySub]));
        }
      } else {
        ret.push(this.toQueryPair(key, values));
      }
    }
    return ret.join('&');
  }
}
