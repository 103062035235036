import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {ApiService} from '../service/api.service';
import {HelperService} from '../service/helper.service';
import {TranslateConfigService} from '../service/translate-config.service';


@Component({
  selector: 'verify-phone-app',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerifyPhoneComponent implements OnInit, OnDestroy {

  disableVerifyPhone = false;
  phoneNo: any;
  confirmationResult: any;

  constructor(
    private hpService: HelperService,
    public modalCtr: ModalController,
    public apiService: ApiService,
    private translateConfigService: TranslateConfigService,
    private alertCtrl: AlertController,
    private navParams: NavParams,
  ) {
    if (this.navParams.get('phone') && this.navParams.get('phone') != '' && this.navParams.get('phone') != null) {
      this.phoneNo = this.navParams.get('phone');
      this.verifyWithPhoneNumber();
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  verifyWithPhoneNumber() {
    this.disableVerifyPhone = true;
    const loaderCustom = this.hpService.loaderCommon();

    let msg = '';
    if (this.phoneNo == null || this.phoneNo === '') {
      msg = this.translateConfigService.getTranslateService().instant('MESSAGE.valid-phone-blank');
    }/* else if (!this.phoneNo.startsWith('+95')) {
      msg = this.translateConfigService.getTranslateService().instant('MESSAGE.valid-phone-format');
    }*/

    if (msg !== '') {
      this.hpService.handleError(msg);
      this.disableVerifyPhone = false;
      loaderCustom.then(ldRes => ldRes.dismiss());
      return;
    }

    const params = {
      phone: this.phoneNo.trim(),
    };

    this.apiService.sendSMS(params, loaderCustom).subscribe(res => {
      if (res.status === this.hpService.ERROR_CODE_SUCCESS) {
        this.otpVerification();
      } else {
        this.hpService.presentToast(res.message);
      }
      this.disableVerifyPhone = false;
    });
    /*   this.authService.signInWithPhoneNumber(this.recaptchaVerifier, '+84' + this.phoneNo).then(
         success => {
           this.otpVerification();
         }
       );*/

  }

  async otpVerification() {
    const alert = await this.alertCtrl.create({
      header: this.translateConfigService.getTranslateService().instant('USER-EDIT.enter-otp'),
      backdropDismiss: false,
      inputs: [
        {
          name: 'otp',
          type: 'text',
          cssClass: 'input-alert',
          placeholder: this.translateConfigService.getTranslateService().instant('USER-EDIT.place-enter-otp'),
        }
      ],
      buttons: [{
        text: this.translateConfigService.getTranslateService().instant('USER-EDIT.btn-enter-otp'),
        handler: (data) => {

          if (data.otp.trim().length !== 5) {
            this.hpService.presentToast(this.translateConfigService.getTranslateService().instant('USER-EDIT.valid-otp-length'));
            return;
          }

          const params = {
            phone: this.phoneNo,
            code: data.otp.trim()
          };

          this.apiService.verifySMS(params).subscribe(res => {
            if (res.status === this.hpService.ERROR_CODE_SUCCESS) {
              this.hpService.presentToast(this.translateConfigService.getTranslateService().instant('USER-EDIT.msg-otp-success'));
              this.modalCtr.dismiss(this.phoneNo);
            } else {
              this.hpService.presentToast(res.message);
            }
          });

          /*this.authService.enterVerificationCode(res.otp).then(
            userData => {
              this.apiService.updateUserPhone({phone: this.phoneNo});
              this.hpService.handlerSuccess(this.translateConfigService.getTranslateService()
              .instant('USER-EDIT.msg-otp-success')).then(res => {
                this.modalCtr.dismiss(this.phoneNo);
              });
            });*/

        }
      }
      ]
    });
    await alert.present();
  }

  async closeModal() {
    await this.modalCtr.dismiss();
  }

}
