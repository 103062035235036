// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  domain: 'https://gavip668.com',
  endPoint: 'https://api1.gavip668.com',
  endPointApi : 'https://api1.gavip668.com/api',
  domainDownload : 'https://tai.gavip668.com',
  VERSION_ANDROID : '1.0',
  VERSION_IOS : '1.0',
  CODE: '',
  tawk: '',

};

// export const environment = {
//   production: false,
//   domain: 'http://localhost:9299',
//   endPoint: 'http://localhost:9299',
//   endPointApi : 'http://localhost:9299/api',
//   domainDownload : 'https://tai.gavip668.com',
//   VERSION_ANDROID : '1.0',
//   VERSION_IOS : '1.0',
//   CODE: '',
//   tawk: 'https://embed.tawk.to/65e474668d261e1b5f67d797/1ho26dckv',

// };
