export class Base64Util {

  decode(s: string): string {
    return decodeURIComponent(escape(s));
  }

  encode(s: string): string {
    return unescape(encodeURIComponent(s));
  }
}
