import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {UserAccessService} from './service/user-access.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'user-edit',
    loadChildren: () => import('./user-edit/user-edit.module').then(m => m.UserEditPageModule)
  },
  /*{
    path: 'lottery-play',
    loadChildren: () => import('./lottery-play/lottery-play.module').then(m => m.LotteryPlayPageModule),
    canActivate: [UserAccessService]
  },*/
  {
    path: 'user-banks',
    loadChildren: () => import('./user-banks/user-banks.module').then(m => m.UserBanksPageModule)
  },
  {
    path: 'gift',
    loadChildren: () => import('./gift/gift.module').then(m => m.GiftPageModule)
  },
  {
    path: 'deposit',
    loadChildren: () => import('./deposit/deposit.module').then(m => m.DepositPageModule)
  },
  {
    path: 'withdraw',
    loadChildren: () => import('./withdraw/withdraw.module').then(m => m.WithdrawPageModule)
  },
  {
    path: 'balance-history',
    loadChildren: () => import('./balance-history/balance-his.module').then(m => m.BalanceHisPageModule)
  },
  {
    path: 'report-agent',
    loadChildren: () => import('./report-agent/report-agent.module').then(m => m.ReportAgentPageModule)
  },
  {
    path: 'report-bonus',
    loadChildren: () => import('./report-bonus/report-bonus.module').then(m => m.ReportBonusPageModule)
  },
  {
    path: 'refa/:agent-code',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'refu/:code',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'user-reference',
    loadChildren: () => import('./user-reference/user-reference.module').then(m => m.UserReferencePageModule)
  },
  {
    path: 'agency',
    loadChildren: () => import('./agency/agency.module').then(m => m.AgencyPageModule),
    canActivate: [UserAccessService]
  },
  {
    path: 'access_token/:access-token',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'download',
    loadChildren: () => import('./download/download.module').then(m => m.DownloadPageModule)
  },
  {
    path: 'download/:code',
    loadChildren: () => import('./download/download.module').then(m => m.DownloadPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
