import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController, NavParams, AlertController} from '@ionic/angular';
import {HelperService} from '../service/helper.service';
import {Router} from '@angular/router';
import {Base64Util} from '../utils/base64Util';
import {ApiService} from '../service/api.service';
import {AccountService} from '../service/account.service';
import {TranslateConfigService} from '../service/translate-config.service';
import {environment} from '../../environments/environment';
import {VerifyPhoneComponent} from '../verify-phone/verify-phone.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignupComponent implements OnInit {
  username = '';
  password = '';
  phone = '';
  email = '';
  confirmPassword = '';
  name = '';
  passwordWithdraw = '';

  code: any;
  agentCode = '';
  acceptTerms: boolean;
  postData: any;
  base64Util: any;
  loading: any;
  disableAgentCode = false;

  isDisableSubmit = true;

  showPass: boolean;
  showConfirmPass: boolean;

  isDesktop: boolean;

  constructor(
    public modalCtr: ModalController,
    private hpService: HelperService,
    private apiService: ApiService,
    private router: Router,
    private navParams: NavParams,
    private alertCtrl: AlertController,
    private accountService: AccountService,
    private route: Router,
    private translateConfigService: TranslateConfigService
  ) {
    this.base64Util = new Base64Util();
    if (this.navParams.get('rqData')) {
      this.postData = this.navParams.get('rqData');
      this.username = this.postData.username;
      this.password = this.postData.password;
      this.phone = this.postData.phone;
      this.email = this.postData.email;
    }

    this.isDesktop = hpService.isDesktop;

    this.agentCode = environment.CODE !== '' ? environment.CODE : localStorage.getItem('agent-code');
    this.code = environment.CODE !== '' ? environment.CODE : localStorage.getItem('code');
    if (this.agentCode != null && this.agentCode != '') {
      this.disableAgentCode = true;
    }

    this.showPass = false;
    this.showConfirmPass = false;
  }

  ngOnInit() {

  }

  async closeModal() {
    await this.modalCtr.dismiss();
  }

  async handleSuccess() {
    const alert = await this.alertCtrl.create({
      header: this.translateConfigService.getTranslateService().instant('MESSAGE.notify'),
      message: this.translateConfigService.getTranslateService().instant('MESSAGE.register-success'),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translateConfigService.getTranslateService().instant('MESSAGE.login-now'),
          cssClass: 'warning',
          handler: () => {
            this.login();
          }
        }
      ]
    });
    await alert.present();
  }

  registerUser() {
    /* if (this.username === '') {
       this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.valid-username-blank'));
       return;
     } else if (this.username.trim().length < 5 || this.username.trim().length > 30) {
       this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.valid-username-length'));
       return;
     } else*/
    if (this.username === '') {
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.valid-phone-blank'));
      return;
    } else if (this.password === '' || this.confirmPassword === '') {
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.valid-password-blank'));
      return;
    } else if (this.password.length < 6) {
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.valid-password-length'));
      return;
    } else if (this.confirmPassword !== this.password) {
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.valid-password-not-mat'));
      return;
    }

    const loaderCustom = this.hpService.loaderCommon();

    const params = {
      login: this.username,
      password: this.password,
      agentCode: this.agentCode,
      phone: this.username,
      email: this.email,
      code: this.code,
      imei: this.hpService.serialDevice,
      name: this.name,
      pass2: this.password
    };
    this.apiService.register(params, loaderCustom).subscribe(res => {

      if (res.status === this.hpService.ERROR_CODE_SUCCESS) {
        this.handleSuccess();
      } else {
        this.hpService.handleError(res.message);
      }
    });
  }

  login() {
    const loaderCustom = this.hpService.loaderCommon();

    const params = {
      username: this.username.trim(),
      password: this.password,
      rememberMe: true,
      fb_token: '',
      imei: this.hpService.serialDevice
    };

    this.apiService.login(params, loaderCustom).subscribe(
      data => {

        if (data.status === this.hpService.ERROR_CODE_SUCCESS) {
          localStorage.setItem('rememberMe', 'true');
          localStorage.setItem('ur', this.username);
          localStorage.setItem('pa', this.base64Util.encode(this.password));
          localStorage.setItem('auth', String(true));
          localStorage.setItem('idToken', String(data.id_token));
          this.closeModal();
        } else {
          this.hpService.handleError(data.message).then(res => {
          });
        }
      }
    );
  }

  onCheckPrivacy(ev: any) {
    if (ev.detail.checked) {
      this.isDisableSubmit = false;
    } else {
      this.isDisableSubmit = true;
    }
  }

  async openVerifyPhoneModal() {
    const modal = await this.modalCtr.create({
      component: VerifyPhoneComponent,
      mode: 'ios',
      cssClass: 'modal-verify-phone',
      componentProps: {
        phone: this.phone
      }
    });

    modal.onDidDismiss()
      .then((ev) => {
        if (ev.data !== undefined) {
          this.phone = ev.data;
        }
      });

    return await modal.present();
  }

}
