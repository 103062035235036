import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EventComponent implements OnInit, OnDestroy {

  screen: any;

  constructor(
    public modalCtr: ModalController,
    private navParams: NavParams
  ) {
    if (this.navParams.get('screen')) {
      this.screen = this.navParams.get('screen');
    }
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
  }

  async closeModal() {
    await this.modalCtr.dismiss();
  }

  async switchScreen(screen) {
    await this.modalCtr.dismiss(screen);
  }
}
