import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {HelperService} from "../service/helper.service";
import {AccountAddBankComponent} from "../user-banks/account-add-bank/account-add-bank.component";
import {ApiService} from "../service/api.service";

@Component({
  selector: 'app-select-bank-account',
  templateUrl: './select-bank-account.component.html',
  styleUrls: ['./select-bank-account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectBankAccountComponent implements OnInit, OnDestroy {

  bankItem = {bankName: '-', bankBranch: '-', bankAccount: '-', bankNumber: '-', image: '', code:''};
  mapBank = new Map();
  withdraw = false;

  constructor(
    public modalCtr: ModalController,
    private navParams: NavParams,
    private hpService: HelperService,
    private apiService: ApiService
  ) {
    if (this.navParams.get('listBank')) {
      const listBank = this.navParams.get('listBank');
      this.mapBank = this.groupBy(listBank, item => item.type);
    }
    if (this.navParams.get('withdraw')) {
      this.withdraw = this.navParams.get('withdraw');
    }
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }


  ngOnInit() {

  }

  ngOnDestroy(): void {
  }

  async selectBank(bankItem: any) {
    await this.modalCtr.dismiss(bankItem);
  }

  async dismissModal() {
    await this.modalCtr.dismiss();
  }

  onCopy(text: string) {
    this.hpService.copyToClipboard(text);
  }

  addUserBank() {
    this.presentModalUserAddBank(null);
  }

  async presentModalUserAddBank(item?: any) {
    const modal = await this.modalCtr.create({
      component: AccountAddBankComponent,
      mode: 'md',
      componentProps: {
        data: item
      }
    });

    modal.onDidDismiss().then(data => {
      this.getListBankAccount();
    });

    return await modal.present();
  }

  getListBankAccount() {
    const loaderCustom = this.hpService.loaderCommon();
    this.apiService.getUserBankList(loaderCustom).subscribe(data => {
      if (data.status === this.hpService.ERROR_CODE_SUCCESS) {
        this.mapBank = this.groupBy(data.data, item => item.type);
      } else {
        this.hpService.handleError(data.message);
      }
    });
  }

}
