import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {HelperService} from "../service/helper.service";
import {ModalController, NavParams} from "@ionic/angular";
import {ApiService} from "../service/api.service";
import {DepositHisListComponent} from "./deposit-his-list/deposit-his-list.component";
import {TranslateConfigService} from "../service/translate-config.service";
import {DepositDetailComponent} from "./deposit-detail/deposit-detail.component";
import {SelectBankAccountComponent} from "../select-bank-account/select-bank-account.component";
import {InAppBrowser} from "@awesome-cordova-plugins/in-app-browser/ngx";
@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.page.html',
  styleUrls: ['./deposit.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DepositPage implements OnInit {

  @ViewChild('moneyItem', {static: false}) moneyItem: ElementRef;

  bankItem: { bankName: any, bankBranch: any, bankAccount: any, bankNumber: any, id: any, remark: any, image: any, code:any };
  listBank = [];
  amount: string;
  description: string;
  isDisableDeposit = true;

  isModal = false;

  constructor(
    private hpService: HelperService,
    private apiService: ApiService,
    public modalCtr: ModalController,
    private navParams: NavParams,
    private translateConfigService: TranslateConfigService,
    private iab: InAppBrowser
  ) {
    this.description = localStorage.getItem('ur');
    this.bankItem = {
      bankName: '-',
      bankBranch: '-',
      bankAccount: '-',
      bankNumber: '-',
      id: null,
      remark: null,
      image: null,
      code:''
    };

    if (this.navParams.get('isModal')) {
      this.isModal = this.navParams.get('isModal');
    }
  }

  ngOnInit() {

  }

  ionViewWillEnter() {
    this.getListBankAccount();
  }

  history() {
    this.presentModalHistory();
  }

  async presentModalHistory() {
    const modal = await this.modalCtr.create({
      component: DepositHisListComponent,
      mode: 'md',
    });
    return await modal.present();
  }

  submit() {
    if (this.bankItem.bankAccount === '' || this.bankItem.bankAccount === '-') {
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.valid-bank-account-blank'));
      return;
    } else if (this.amount === '' || /[^0-9,]/g.test(this.amount)) {
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.invalid-amount'));
      return;
    } else if (this.bankItem.code === '') {
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.valid-bank-account-blank'));
      return;
    }

    const loaderCustom = this.hpService.loaderCommon();
        this.deposit(loaderCustom);
  }

  deposit(loaderCustom: any) {
    let data = {
      'amount': Number(this.amount.replace(/[,]/g, '')),
      'bAccount': this.bankItem.bankAccount,
      'bid': this.bankItem.id,
      'bname': this.bankItem.bankName,
      'remark': this.description,
      'login': localStorage.getItem('ur'),
      'code': this.bankItem.code
    };
    
    this.apiService.createDeposit(data, loaderCustom).subscribe(res => {
      if (res.status === this.hpService.ERROR_CODE_SUCCESS) {
        console.log(data);
        // this.presentModalHistory();
        this.presentModalDetail(res.data);
      
        this.closeModal();
      //  this.hpService.handlerSuccess(this.translateConfigService.getTranslateService().instant('DEPOSIT.deposit-success'), this.presentModalHistory(),
      //        this.translateConfigService.getTranslateService().instant('DEPOSIT.deposit-success-title'));
      } else {
        this.hpService.handleError(res.message);
      }
    });
  }

  async presentModalDetail(res: any) {
    const modal = await this.modalCtr.create({
      component: DepositDetailComponent,
      mode: 'md',
      cssClass: "modal-deposit-bottom",
      componentProps: {
        "item": res
      }

    });
    return await modal.present();
  }

  onSelectAccount(index) {
    // this.presentModalSelectBankAccount();
    this.bankItem = this.listBank[index];
  }

  async presentModalSelectBankAccount() {
    const modal = await this.modalCtr.create({
      component: SelectBankAccountComponent,
      mode: 'md',
      componentProps: {
        "listBank": this.listBank
      }
    });

    modal.onDidDismiss()
      .then((ev) => {
        if (ev.data !== undefined) {
          this.bankItem = ev.data;
        }
      });

    return await modal.present();
  }

  getListBankAccount() {
    const loaderCustom = this.hpService.loaderCommon();
    this.apiService.getAdminBankList(loaderCustom).subscribe(data => {
      if (data.status === this.hpService.ERROR_CODE_SUCCESS) {
        this.listBank = data.data;
        if (this.listBank != null && this.listBank.length > 0) {
          this.bankItem = this.listBank[0];
        }
      } else {
        this.hpService.handleError(data.message);
      }
    });
  }

  getDiem() {
    return Number(this.amount.replace(/[,]/g, '')) / 30000;
  }

  updateMoneyKeyUp(ev: any) {
    const getMoneyRegx = ev.replace(/[,]/g, '');
    this.amount = HelperService.numberWithCommas(Number(getMoneyRegx));
    this.amount === '0' ? this.moneyItem['el'].value = '' : this.moneyItem['el'].value = this.amount;

    if (this.amount != '0') {
      this.isDisableDeposit = false;
    } else {
      this.isDisableDeposit = true;
    }
  }

  /*  updateDescKeyUp(ev: any) {
      console.log(this.description);
      if (this.amount != '0' && this.description != '' && this.description != undefined) {
        this.isDisableDeposit = false;
      } else {
        this.isDisableDeposit = true;
      }
    }*/

  async closeModal() {
    await this.modalCtr.dismiss();
  }

  moneyToggle(amount: any) {
    this.amount = HelperService.numberWithCommas(Number(amount));
    if (this.amount != '0') {
      this.isDisableDeposit = false;
    } else {
      this.isDisableDeposit = true;
    }
  }
}
