import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {TranslateConfigService} from "../../service/translate-config.service";
import {HelperService} from "../../service/helper.service";
import {ApiService} from "../../service/api.service";

@Component({
  selector: 'app-deposit-detail',
  templateUrl: './deposit-detail.component.html',
  styleUrls: ['./deposit-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DepositDetailComponent implements OnInit, OnDestroy {

  item: any;
  fileUpload: any;
  isDisableDeposit = true;

  constructor(
    private apiService: ApiService,
    private hpService: HelperService,
    public modalCtr: ModalController,
    private navParams: NavParams,
    private translateConfigService: TranslateConfigService
  ) {
    if (this.navParams.get('item')) {
      this.item = this.navParams.get('item');
      this.item.note = this.translateConfigService.getTranslateService().instant('DEPOSIT.detail-note').replace("{remark}", this.item.remark);
    }
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
  }

  async closeModal() {
    await this.modalCtr.dismiss();
  }

  onCopy(text: string) {
    this.hpService.copyToClipboard(text);
  }

  onFileChange(fileChangeEvent) {
    if (fileChangeEvent.target.files.length > 0) {
      this.fileUpload = fileChangeEvent.target.files[0];
      this.isDisableDeposit = false;
    } else {
      this.isDisableDeposit = true;
    }
  }

  upload() {
    const loaderCustom = this.hpService.loaderCommon();

    const payload = new FormData();
    payload.append('id', this.item.id);
    payload.append('file', this.fileUpload, this.fileUpload.name);

    this.apiService.uploadDeposit(payload, loaderCustom).subscribe(res => {
      if (res.status === this.hpService.ERROR_CODE_SUCCESS) {
        this.hpService.handlerSuccess(this.translateConfigService.getTranslateService().instant('DEPOSIT.deposit-success'), () => {
            this.closeModal();
          },
          this.translateConfigService.getTranslateService().instant('DEPOSIT.deposit-success-title'));
      } else {
        this.hpService.handleError(res.message);
      }
    });
  }

}
