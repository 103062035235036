import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {HelperService} from "../service/helper.service";
import {ApiService} from "../service/api.service";

@Component({
  selector: 'app-select-bank',
  templateUrl: './select-bank.component.html',
  styleUrls: ['./select-bank.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectBankComponent implements OnInit, OnDestroy {

  listBank = [];

  constructor(
    public modalCtr: ModalController,
    private hpService: HelperService,
    private apiService: ApiService
  ) {

  }

  ngOnInit() {
    this.getListBank();
  }

  ngOnDestroy(): void {
  }

  getListBank() {
    this.apiService.getListBank().subscribe(data => {
      if (data.status === this.hpService.ERROR_CODE_SUCCESS) {
        this.listBank = data.data;
      } else {
        this.hpService.handleError(data.message);
      }
    });
  }

  async selectBank(bankItem: any) {
    await this.modalCtr.dismiss(bankItem);
  }

  async dismissModal() {
    await this.modalCtr.dismiss();
  }

}
