import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy, NavParams} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {SignupComponent} from './signup/signup.component';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import {GameComponent} from './game/game.component';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateConfigService} from './service/translate-config.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {UpdateLoginComponent} from './update-login/update-login.component';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {SharedPipeModule} from './pipe/shared-pipe/shared-pipe.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DatePipe} from '@angular/common';
import {SearchComponent} from './search/search.component';
import {EventComponent} from './event/event.component';
import {SelectBankAccountComponent} from './select-bank-account/select-bank-account.component';
import {SelectBankComponent} from './select-bank/select-bank.component';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {Device} from '@awesome-cordova-plugins/device/ngx';
import { environment } from 'src/environments/environment';
import { AngularFireModule} from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {VerifyPhoneComponent} from './verify-phone/verify-phone.component';
import {UpdatePasswordComponent} from "./update-password/update-password.component";
import {NotifyComponent} from "./notify/notify.component";

export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    GameComponent,
    UpdateLoginComponent,
  /*  TxcbComponent,
    BxhComponent,
    LspComponent,
    LscComponent,
    TkpComponent,
    HdComponent,
    TrenduoiComponent,
    LscTrenDuoiComponent,
    HdTrenDuoiComponent,
    BxhTrenDuoiComponent,
    XocdiaComponent,
    BxhXocDiaComponent,
    HdXocDiaComponent,
    LscXocDiaComponent,
    LspXocDiaComponent,
    TkpXocDiaComponent,*/
    SearchComponent,
    EventComponent,
    SelectBankAccountComponent,
    SelectBankComponent,
    VerifyPhoneComponent,
    UpdatePasswordComponent,
    NotifyComponent
  ],
  entryComponents: [
    LoginComponent,
    SignupComponent,
    GameComponent,
    UpdateLoginComponent,
  /*  TxcbComponent,
    BxhComponent,
    LspComponent,
    LscComponent,
    TkpComponent,
    HdComponent,
    TrenduoiComponent,
    LscTrenDuoiComponent,
    HdTrenDuoiComponent,
    BxhTrenDuoiComponent,
    XocdiaComponent,
    BxhXocDiaComponent,
    HdXocDiaComponent,
    LscXocDiaComponent,
    LspXocDiaComponent,
    TkpXocDiaComponent,*/
    SearchComponent,
    EventComponent,
    SelectBankAccountComponent,
    SelectBankComponent,
    VerifyPhoneComponent,
    UpdatePasswordComponent,
    NotifyComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (LanguageLoader),
        deps: [HttpClient]
      }
    }),
    SharedPipeModule,
    BrowserAnimationsModule,
  //  AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule
  ],

  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    InAppBrowser,
    ScreenOrientation,
    TranslateConfigService,
    AppVersion,
    DatePipe,
    Deeplinks,
    StatusBar,
    NavParams,
    Device
  ],
  bootstrap: [AppComponent],
  exports: [
    TranslateModule
  ]
})
export class AppModule {
}
