import {AfterViewInit, Component, NgZone} from '@angular/core';
import {SplashScreen} from '@capacitor/splash-screen';
import {ModalController, NavController, Platform} from '@ionic/angular';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {HelperService} from './service/helper.service';
import {ApiService} from './service/api.service';
import {TranslateConfigService} from './service/translate-config.service';
import {Capacitor} from '@capacitor/core';
import {environment} from '../environments/environment';
import {AccountService} from './service/account.service';
import {App} from '@capacitor/app';
import {Router} from '@angular/router';
import {Deeplinks} from '@awesome-cordova-plugins/deeplinks/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Device} from '@awesome-cordova-plugins/device/ngx'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  lastBack: any;

  constructor(
    private platform: Platform,
    private iab: InAppBrowser,
    private hpService: HelperService,
    private apiService: ApiService,
    private accountService: AccountService,
    private translateConfigService: TranslateConfigService,
    private modalCtr: ModalController,
    private statusBar: StatusBar,
    private zone: NgZone,
    private router: Router,
    private navController: NavController,
    private deeplinks: Deeplinks,
    private device: Device
  ) {
    SplashScreen.show({
      showDuration: 5000,
      autoHide: true
    });

    this.platform.backButton.subscribeWithPriority(0, () => {
      if (Date.now() - this.lastBack < 500) { // logic for double tap: delay of 500ms between two clicks of back button
        App.exitApp();
      }
      this.lastBack = Date.now();
    });

   // this.shouldUpdate();

    if (this.accountService.authentication) {
      this.checkExpired();
    }

    this.getPlatform();

    this.platform.ready().then(() => {
      if (this.hpService.isIOSDevice) {
        this.statusBar.styleLightContent();
        this.statusBar.overlaysWebView(true);
      }
    });

  }

  shouldUpdate() {
    this.translateConfigService.getDefaultLanguage();
    this.apiService.getAppVersion().subscribe(
      res => {
        if (res.status === this.hpService.ERROR_CODE_SUCCESS) {
          let android: any;
          let ios: any;

          for (const item of res.data) {
            if (item.platform === 'a') {
              android = item;
              const url = (android.store == null || android.store === '') ? android.url : android.store;
              localStorage.setItem('android-link', url);
            }
            if (item.platform === 'i') {
              ios = item;
              const url = (ios.store == null || ios.store === '') ? ios.url : ios.store;
              localStorage.setItem('ios-link', url);
            }
          }

          if (Capacitor.isNativePlatform() && this.platform.is('android')) {
            if (environment.VERSION_ANDROID < android.latest) {
              this.hpService.confirmUpdateAppVersion(() => {
                this.iab.create(localStorage.getItem('android-link'), `_blank`, 'usewkwebview = yes');
              });
            }
          }

          if (Capacitor.isNativePlatform() && this.platform.is('ios')) {
            if (environment.VERSION_IOS < ios.latest) {
              this.hpService.confirmUpdateAppVersion(() => {
                this.iab.create(localStorage.getItem('ios-link'), `_blank`, 'usewkwebview = yes');
              });
            }
          }

        }
      });
    // "https://play.google.com/store/apps/details?id=com.example.app&hl=en"
    //  "itms-apps://itunes.apple.com/br/app/example-app/id1234567890?mt=8"
  }

  checkExpired() {
    this.apiService.verifyToken({}).subscribe();
  }

  getPlatform() {
    const getPlatforms = this.platform.platforms();
    if (getPlatforms.indexOf('desktop') > -1) {
      this.hpService.isDesktop = true;
    } else if (getPlatforms.indexOf('mobileweb') > -1 && getPlatforms.indexOf('ios') > -1) {
      this.hpService.isMobileWeb = true;
      this.hpService.isIOSDevice = true;
      this.hpService.presentToast('Để chơi game bạn vui lòng tắt tính năng Block popup!');
    } else if (getPlatforms.indexOf('mobileweb') > -1 && getPlatforms.indexOf('android') > -1) {
      this.hpService.isMobileWeb = true;
      this.hpService.isAndroidDevice = true;
    } else if (getPlatforms.indexOf('ios') > -1) {
      this.hpService.isMobileNative = true;
      this.hpService.isIOSDevice = true;
    } else if (getPlatforms.indexOf('android') > -1) {
      this.hpService.isMobileNative = true;
      this.hpService.isAndroidDevice = true;
    }
    this.hpService.serialDevice = this.device.uuid;
  }

  ngAfterViewInit() {
    /*    if (this.platform.is('mobile')) {
          this.deeplinks.routeWithNavController(this.navController, {'/ref/:agent-code': HomePage}).subscribe(
            (match) => {
              console.log('Successfully matched route', match);

              // Create our internal Router path by hand
              const internalPath = '/ref/:agent-code';

              // Run the navigation in the Angular zone
              this.zone.run(() => {
                this.router.navigateByUrl(internalPath);
              });
            },
            (nomatch) => {
              // nomatch.$link - the full link data
              //console.error('Got a deeplink that did match', nomatch);
            }
          );
        }*/
  }

}
