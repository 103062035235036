import {Injectable} from '@angular/core';
import {AccountService} from './account.service';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {HelperService} from "./helper.service";
import {TranslateConfigService} from "./translate-config.service";

@Injectable({
  providedIn: 'root'
})
export class UserAccessService implements CanActivate {

  constructor(
    private accountService: AccountService,
    private hpService: HelperService,
    private router: Router,
    private translateConfigService: TranslateConfigService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.accountService.authentication || this.accountService.tokenId == null
      || this.accountService.tokenId === undefined) {
      
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.login'), () => {
        this.accountService.resetAuth();
        this.router.navigateByUrl('');
      });

      return false;
    }
    return true;
  }
}
