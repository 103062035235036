import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {HelperService} from '../service/helper.service';
import {TranslateConfigService} from '../service/translate-config.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit, OnDestroy {

  searchParam = {
    startTime: new Date().toISOString(),
    endTime: new Date().toISOString(),
    login: null,
    date: new Date().toISOString()
  };
  time = false;
  login = false;
  date = false;

  constructor(
    public modalCtr: ModalController,
    private navParams: NavParams,
    private hpService: HelperService,
    private translateConfigService: TranslateConfigService
  ) {
    if (this.navParams.get('time')) {
      this.time = this.navParams.get('time');
    }
    if (this.navParams.get('login')) {
      this.login = this.navParams.get('login');
    }
    if (this.navParams.get('date')) {
      this.date = this.navParams.get('date');
    }
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
  }

  async acceptModal() {
    if (this.time) {
      const startDate = new Date(this.searchParam.startTime);
      const endDate = new Date(this.searchParam.endTime);
      if (startDate.getTime() > endDate.getTime()) {
        this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.valid-date-invalid'));
        return;
      }
    }
    await this.modalCtr.dismiss(this.searchParam);
  }

  async dismissModal() {
    await this.modalCtr.dismiss();
  }

}
