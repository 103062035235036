import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customNumber'
})
export class CustomNumberPipe implements PipeTransform {
  transform(value: any, format?: string): any {
    return this.numberWithCommas(this.formatNum(value, format));
  }
  formatNum(value?: any, format?: string) {
    if (isNaN(value)) return value;
    if (!value) return 0;
    if (!format) return Number(value);
    if (format === 'int') {
      return Math.floor(value);
    } else if (format === 'round') {
      return Math.round(value);
    } else if (format.startsWith('*.')) {
      let decimalNums = format.split('*.')[1];
      if (isNaN(Number(decimalNums)) || Number(decimalNums) < 0) {
        return Number(value);
      } else {
        return Number(value.toFixed(decimalNums));
      }
    }
    return Number(value);
  }
  numberWithCommas(num: number): string {
    let numStr = num.toString();
    if (numStr.includes('.')) {
      const parts = numStr.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    } else {
      return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }
}
