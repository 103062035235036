import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ApiService} from '../service/api.service';
import {Router} from '@angular/router';
import {HelperService} from '../service/helper.service';
import {TranslateConfigService} from "../service/translate-config.service";
import {reduce} from "rxjs/operators";

@Component({
  selector: 'app-update-login',
  templateUrl: './update-login.component.html',
  styleUrls: ['./update-login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdateLoginComponent implements OnInit, OnDestroy {
  username = '';
  agentCode = '';

  constructor(
    private hpService: HelperService,
    public modalCtr: ModalController,
    public apiService: ApiService,
    private route: Router,
    private translateConfigService: TranslateConfigService
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  async closeModal() {
    await this.modalCtr.dismiss();
    const getUrlStore = localStorage.getItem('priviousState');
    localStorage.removeItem('priviousState');
    if (getUrlStore) {
      await this.route.navigateByUrl('');
    }
  }

  updateLogin(ev: any) {
    if (this.username === '') {
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.valid-username-blank'));
      return;
    } else if (this.username.trim().length < 5 || this.username.trim().length > 30) {
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.valid-username-length'));
      return;
    } else if (!this.hpService.checkName(this.username)) {
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.valid-username-invalid'));
      return;
    }

    const loaderCustom = this.hpService.loaderCommon();

    const params = {
      username: this.username.trim(),
      agent: this.agentCode.trim(),
      accessToken: localStorage.getItem("accessTokenFB")
    };

    this.apiService.updateLogin(params, loaderCustom).subscribe(
      data => {
        if (data.status === this.hpService.ERROR_CODE_SUCCESS) {
          localStorage.setItem('isUpdateLogin', String(true));
          this.closeModal().then(res => {
          });
        } else {
          localStorage.setItem('isUpdateLogin', String(false));
          this.hpService.handleError(data.message).then(res => {
          });
        }
      }
    );
  }
}
