import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {ApiService} from '../service/api.service';
import {HelperService} from '../service/helper.service';
import {Router} from '@angular/router';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {DepositPage} from '../deposit/deposit.page';
import {StatusBar} from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GameComponent implements OnInit, OnDestroy {
  urlGame = '';
  showBanner = true;
  srcBanner = '';

  isShow = false;

  constructor(
    private hpService: HelperService,
    public modalCtr: ModalController,
    public apiService: ApiService,
    private route: Router,
    private navParams: NavParams,
    private screenOrientation: ScreenOrientation,
    private statusBar: StatusBar
  ) {
    if (this.navParams.get('urlGame')) {
      this.urlGame = this.navParams.get('urlGame');
      setTimeout(() => {
        this.showBanner = false;
      }, 5000);
    }

    this.getBannerGame(this.screenOrientation.type);
    this.screenOrientation.onChange().subscribe(
      () => {
        this.getBannerGame(this.screenOrientation.type);
        // console.log(this.screenOrientation.type);
      });
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
  }

  async closeModal(toHome = false) {
    if (toHome) {
      await this.modalCtr.dismiss({toHome: true});
    } else {
      await this.modalCtr.dismiss();
    }
  }

  async goToScreen(screenName: any) {
    /*await this.modalCtr.dismiss();
    this.hpService.observableSwitchScreen.next({type: 1, screen: screenName});*/
    if (screenName === 'deposit') {
      this.presentModalDeposit();
    } /*else if (screenName === 'fund-transfer') {
      this.presentModalFundTransfer();
    }*/
  }

  getBannerGame(soType: any) {
    if (soType === 'landscape-primary') {
      this.srcBanner = '../assets/banner/banner_game_landscape_' + this.hpService.randomInteger(1, 3) + '.png';
    } else {
      this.srcBanner = '../assets/banner/banner_game_portrait_' + this.hpService.randomInteger(1, 2) + '.png';
    }
  }

  showHideSubAction() {
    this.isShow = !this.isShow;
    const subBtnList = document.getElementsByClassName('sub-btn');
    Array.from(subBtnList).forEach((el) => {
      if (this.isShow) {
        el.classList.remove('off');
        el.classList.add('on');
      } else {
        el.classList.remove('on');
        el.classList.add('off');
      }
    });

  }

  async presentModalDeposit() {
    const modal = await this.modalCtr.create({
      component: DepositPage,
      mode: 'md',
    });
    return await modal.present();
  }
}
