import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ApiService} from '../service/api.service';
import {Router} from '@angular/router';
import {HelperService} from '../service/helper.service';
import {FacebookLogin, FacebookLoginResponse} from '@capacitor-community/facebook-login';
import {Base64Util} from '../utils/base64Util';
import {TranslateConfigService} from '../service/translate-config.service';
import {UpdateLoginComponent} from '../update-login/update-login.component';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
  username = '';
  password = '';
  base64Util: any;
  isSaveInfo = false;
  showPass: boolean;

  qrAndroid = '';
  qrIOS = '';

  constructor(
    private hpService: HelperService,
    public modalCtr: ModalController,
    public apiService: ApiService,
    private route: Router,
    private translateConfigService: TranslateConfigService,
    private iab: InAppBrowser
  ) {
    this.base64Util = new Base64Util();
    this.showPass = false;
    this.getQRCode();
  }

  ngOnInit() {
    setTimeout(() => {
      const getRememberMe = localStorage.getItem('rememberMe');
      if (getRememberMe === 'true') {
        const getStoreUsername = localStorage.getItem('ur');
        const getStorePassword = localStorage.getItem('pa');
        if (getStoreUsername && getStorePassword) {
          this.isSaveInfo = true;
          this.username = getStoreUsername;
          this.password = this.base64Util.decode(getStorePassword);
        }
      } else {
        this.username = '';
        this.password = '';
      }
    }, 500);
  }

  ngOnDestroy(): void {
  }

  async closeModal(forgetPass?: boolean) {
    await this.modalCtr.dismiss();
    const getUrlStore = localStorage.getItem('priviousState');
    localStorage.removeItem('priviousState');
    if (getUrlStore) {
      await this.route.navigateByUrl('');
    }
  }

  login(ev: any) {
    if (this.username === '') {
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.valid-username-blank'));
      return;
    } else if (this.password === '') {
      this.hpService.handleError(this.translateConfigService.getTranslateService().instant('MESSAGE.valid-password-blank'));
      return;
    }

    const loaderCustom = this.hpService.loaderCommon();

    const params = {
      username: this.username.trim(),
      password: this.password,
      rememberMe: this.isSaveInfo,
      imei : this.hpService.serialDevice
    };

    this.apiService.login(params, loaderCustom).subscribe(
      data => {
        if (data.status === this.hpService.ERROR_CODE_SUCCESS) {
          this.isSaveInfo ? localStorage.setItem('rememberMe', 'true') : localStorage.setItem('rememberMe', 'false');
          localStorage.setItem('ur', this.username);
          localStorage.setItem('pa', this.base64Util.encode(this.password));
          localStorage.setItem('auth', String(true));
          localStorage.setItem('idToken', String(data.id_token));
          const date = new Date();
          localStorage.setItem('tokenExpired', String(date.getTime()));
          this.closeModal().then(res => {

          });
        } else {
          this.hpService.handleError(data.message).then(res => {
          });
        }
      }
    );
  }

  async loginFacebook() {
    const FACEBOOK_PERMISSIONS = ['email', 'public_profile'];
    const result = <FacebookLoginResponse>await FacebookLogin.login({permissions: FACEBOOK_PERMISSIONS});

    if (result.accessToken) {
      // Login successful.
      // console.log(`Facebook access token is ${result.accessToken.token}`);
      this.registerUser(result.accessToken.token);
    }
  }

  registerUser(fbToken) {
    const params = {
      login: '123456aa',
      password: '123456aa',
      agent: '',
      fb_token: fbToken,
      imei : this.hpService.serialDevice
    };
    this.apiService.register(params).subscribe(res => {
      if (res.status === this.hpService.ERROR_CODE_SUCCESS || res.status === this.hpService.ERROR_UPDATE_LOGIN) {
        localStorage.setItem('ur', res.data.login);
        localStorage.setItem('pa', this.base64Util.encode(res.data.fbId));
        localStorage.setItem('auth', String(true));
        localStorage.setItem('idToken', String(res.message));
        localStorage.setItem('accessTokenFB', fbToken);
        localStorage.setItem('nickName', res.data.name);
        const date = new Date();
        localStorage.setItem('tokenExpired', String(date.getTime()));
        localStorage.setItem('isUpdateLogin', String(true));
        this.closeModal();
        if (res.status === this.hpService.ERROR_UPDATE_LOGIN) {
          localStorage.setItem('isUpdateLogin', String(false));
          this.presentModalUpdateLogin();
        }
      } else {
        this.hpService.handleError(res.message);
      }
    });
  }

  async presentModalUpdateLogin() {
    const modal = await this.modalCtr.create({
      component: UpdateLoginComponent,
      mode: 'md'
    });
    return await modal.present();
  }

  async getProfileFacebook() {
    const result = await FacebookLogin.getProfile<{
      email: string;
      id: string;
      name: string;
    }>({fields: ['email', 'id', 'name']});
    // console.log(`Facebook user's email is ${result.email} ${result.id} ${result.name}`);
    //  this.registerUser(result.email)
  }

  downloadApp() {
    if (this.hpService.isIOSDevice) {
      this.iab.create(localStorage.getItem('ios-link'), `_blank`, 'usewkwebview = yes');
    } else {
      this.iab.create(localStorage.getItem('android-link'), `_blank`, 'usewkwebview = yes');
    }
  }

  async getQRCode() {
    this.apiService.getQRCode().subscribe(
      res => {
        this.qrAndroid = 'data:image/jpeg;base64,' + res.qr_android;
        this.qrIOS = 'data:image/jpeg;base64,' + res.qr_ios;
      });
  }

}
