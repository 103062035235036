import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {ApiService} from '../../service/api.service';
import {HelperService} from '../../service/helper.service';
import {TranslateConfigService} from '../../service/translate-config.service';
import {SelectBankComponent} from "../../select-bank/select-bank.component";

@Component({
  selector: 'app-account-add-bank',
  templateUrl: './account-add-bank.component.html',
  styleUrls: ['./account-add-bank.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountAddBankComponent implements OnInit {

  @ViewChild('elInputBankAccount', {static: false}) elInputBankAccount: ElementRef;
  @ViewChild('elInputBankNumber', {static: false}) elInputBankNumber: ElementRef;

  isEdit = false;
  bankItem: { bankName: any, bankBranch: any, bankAccount: any, bankNumber: any, id: any, login: any, image: any, code:any };
  title: any;
  button: any;

  constructor(
    private readonly apiService: ApiService,
    private readonly hpService: HelperService,
    private readonly modalCtr: ModalController,
    private navParams: NavParams,
    private translateConfigService: TranslateConfigService
  ) {
    if (this.navParams.get('data')) {
      this.bankItem = this.navParams.get('data');
      this.title = this.translateConfigService.getTranslateService().instant('USER-BANK.title-edit');
      this.button = this.translateConfigService.getTranslateService().instant('USER-BANK.btn-edit');
      this.isEdit = true;
    } else {
      this.bankItem = {bankName: null, bankBranch: null, bankAccount: null, bankNumber: null, id: null, login: null, image: '', code:''};
      this.title = this.translateConfigService.getTranslateService().instant('USER-BANK.title-add');
      this.button = this.translateConfigService.getTranslateService().instant('USER-BANK.btn-add');
    }
  }

  ngOnInit() {
  }

  submit() {
    let msg = '';
    if (!this.bankItem.bankName && this.bankItem.bankName === '') {
      msg = this.translateConfigService.getTranslateService().instant('MESSAGE.valid-bank-name-blank');
    } /*else if (!this.bankItem.bankBranch && this.bankItem.bankBranch === '') {
      msg = this.translateConfigService.getTranslateService().instant('MESSAGE.valid-bank-branch-blank');
    }*/ else if (!this.bankItem.bankAccount && this.bankItem.bankAccount === '') {
      msg = this.translateConfigService.getTranslateService().instant('MESSAGE.valid-bank-account-blank');
    } else if (this.bankItem.bankNumber !== this.bankItem.bankNumber) {
      msg = this.translateConfigService.getTranslateService().instant('MESSAGE.valid-bank-number-blank');
    }

    if (msg !== '') {
      this.hpService.handleError(msg);
      return;
    }

    if (this.isEdit) {
      this.hpService.confirmAlert(this.translateConfigService.getTranslateService().instant('USER-BANK.content-confirm-edit'), () => {
        this.updateBank();
      });
    } else {
      this.hpService.confirmAlert(this.translateConfigService.getTranslateService().instant('USER-BANK.content-confirm-add'), () => {
        this.addBank();
      });
    }
  }

  addBank() {
    const loaderCustom = this.hpService.loaderCommon();
    this.bankItem.login = localStorage.getItem('ur');
    this.apiService.createUserBank(this.bankItem, loaderCustom).subscribe(res => {
      if (res.status === this.hpService.ERROR_CODE_SUCCESS) {
        /*this.hpService.handlerSuccess(this.translateConfigService.getTranslateService().instant('USER-BANK.add-bank-success')).then(res => {
          this.closeModal();
        });*/
        this.closeModal();
      } else {
        this.hpService.handleError(res.message);
      }
    });
  }

  updateBank() {
    const loaderCustom = this.hpService.loaderCommon();
    this.bankItem.login = localStorage.getItem('ur');
    this.apiService.updateUserBank(this.bankItem, loaderCustom).subscribe(res => {
      if (res.status === this.hpService.ERROR_CODE_SUCCESS) {
        /* this.hpService.handlerSuccess(this.translateConfigService.getTranslateService().instant('USER-BANK.edit-bank-success')).then(res => {
           this.closeModal();
         });*/
        this.closeModal();
      } else {
        this.hpService.handleError(res.message);
      }
    });
  }

  checkBankAccount(ev) {
    ev.preventDefault();
    const bankAccount = ev.target.value;
    this.elInputBankAccount['el'].value = this.hpService.changeAlias(bankAccount, true);
  }

  checkBankNumber(ev) {
    ev.preventDefault();
    const bankNumber = ev.target.value;
    this.elInputBankNumber['el'].value = this.hpService.changeAlias(bankNumber);
  }

  async closeModal() {
    await this.modalCtr.dismiss();
  }

  onSelectBank() {
    this.presentModalSelectBank();
  }

  async presentModalSelectBank() {
    const modal = await this.modalCtr.create({
      component: SelectBankComponent,
      mode: 'md',
    });

    modal.onDidDismiss()
      .then((ev) => {
        if (ev.data !== undefined) {
          const item = ev.data;
          this.bankItem.bankName = item.name;
          this.bankItem.id = item.id;
          this.bankItem.image = item.image;
        }
      });

    return await modal.present();
  }
}
